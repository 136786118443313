import {z} from "zod";

/* eslint-disable @typescript-eslint/naming-convention */

const environmentVariablesSchema = z.object({
  AMPLITUDE_API_KEY: z.string().min(1, {message: "Required"}),
  AMPLITUDE_API_URL: z.string().optional(),
  ASSET_MANAGER_APP_URL: z.string().min(1, {message: "Required"}),
  AUTH0_AUDIENCE: z.string().min(1, {message: "Required"}),
  AUTH0_CLIENT_ID: z.string().min(1, {message: "Required"}),
  AUTH0_DOMAIN: z.string().min(1, {message: "Required"}),
  DEAL_ROOM_ADMIN_APP_URL: z.string().min(1, {message: "Required"}),
  DEAL_ROOM_API_URL: z.string().min(1, {message: "Required"}),
  DEAL_SYNC_MF_TEMPLATE_URL: z.string().min(1, {message: "Required"}),
  DEAL_SYNC_NNN_TEMPLATE_URL: z.string().min(1, {message: "Required"}),
  ESTIMATE_API_URL: z.string().min(1, {message: "Required"}),
  GOOGLE_MAPS_API_KEY_PLACES: z.string().min(1, {message: "Required"}),
  GOOGLE_MAPS_API_KEY: z.string().min(1, {message: "Required"}),
  KEY_ASSIST_API_TOKEN: z.string().min(1, {message: "Required"}),
  KEY_ASSIST_API_URL: z.string().min(1, {message: "Required"}),
  KEYBRAIN_APP_URL: z.string().min(1, {message: "Required"}),
  KEYCOMPS_APP_URL: z.string().min(1, {message: "Required"}),
  KEYDOCS_APP_URL: z.string().min(1, {message: "Required"}),
  KEYHUB_APP_URL: z.string().min(1, {message: "Required"}),
  KEYPILOT_APP_URL: z.string().min(1, {message: "Required"}),
  KFILE_SERVICE_DOMAIN: z.string().min(1, {message: "Required"}),
  ORGANIZATIONS_API_URL: z.string().min(1, {message: "Required"}),
  PROPERTY_SAGE_APP_URL: z.string().min(1, {message: "Required"}),
  SPLIT_IO_BROWSER_API_KEY: z.string().min(1, {message: "Required"}),
  USERBACK_ACCESS_TOKEN: z.string().optional()
});

type EnvironmentVariables = z.infer<typeof environmentVariablesSchema>;

const environmentVariables = environmentVariablesSchema.parse({
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  AMPLITUDE_API_URL: process.env.NEXT_PUBLIC_AMPLITUDE_API_URL,
  ASSET_MANAGER_APP_URL: process.env.NEXT_PUBLIC_ASSET_MANAGER_APP_URL,
  AUTH0_AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  DEAL_ROOM_ADMIN_APP_URL: process.env.NEXT_PUBLIC_DEAL_ROOM_ADMIN_APP_URL,
  DEAL_ROOM_API_URL: process.env.NEXT_PUBLIC_DEAL_ROOM_API_URL,
  DEAL_SYNC_MF_TEMPLATE_URL: process.env.NEXT_PUBLIC_DEAL_SYNC_MF_TEMPLATE_URL,
  DEAL_SYNC_NNN_TEMPLATE_URL:
    process.env.NEXT_PUBLIC_DEAL_SYNC_NNN_TEMPLATE_URL,
  ESTIMATE_API_URL: process.env.NEXT_PUBLIC_ESTIMATE_API_URL,
  GOOGLE_MAPS_API_KEY_PLACES:
    process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY_PLACES,
  GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  KEY_ASSIST_API_TOKEN: process.env.NEXT_PUBLIC_KEY_ASSIST_API_TOKEN,
  KEY_ASSIST_API_URL: process.env.NEXT_PUBLIC_KEY_ASSIST_API_URL,
  KEYBRAIN_APP_URL: process.env.NEXT_PUBLIC_KEYBRAIN_APP_URL,
  KEYCOMPS_APP_URL: process.env.NEXT_PUBLIC_KEYCOMPS_APP_URL,
  KEYDOCS_APP_URL: process.env.NEXT_PUBLIC_KEYDOCS_APP_URL,
  KEYHUB_APP_URL: process.env.NEXT_PUBLIC_KEYHUB_APP_URL,
  KEYPILOT_APP_URL: process.env.NEXT_PUBLIC_KEYPILOT_APP_URL,
  KFILE_SERVICE_DOMAIN: process.env.NEXT_PUBLIC_KFILE_SERVICE_DOMAIN,
  ORGANIZATIONS_API_URL: process.env.NEXT_PUBLIC_ORGANIZATIONS_API_URL,
  PROPERTY_SAGE_APP_URL: process.env.NEXT_PUBLIC_PROPERTY_SAGE_APP_URL,
  SPLIT_IO_BROWSER_API_KEY: process.env.NEXT_PUBLIC_SPLIT_IO_BROWSER_API_KEY,
  USERBACK_ACCESS_TOKEN: process.env.NEXT_PUBLIC_USERBACK_ACCESS_TOKEN
});

export default environmentVariables;

export type {EnvironmentVariables};
